module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ALLTOP ASIA","short_name":"ALLTOP ASIA","description":"ALLTOP ASIA Casino Online","display":"standalone","start_url":"/","background_color":"#000000","theme_color":"#000000","icon":"src/icons/devices/app-icon.png","crossOrigin":"use-credentials","orientation":"portrait","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4f04c2b2bfc26c7b861d2eb0db484271"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/admin/Documents/alltimebet/productions/alltopasia-provider-app/src/intl","languages":["th","en"],"defaultLanguage":"th","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168103073-1","head":false,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
